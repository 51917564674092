<template>
<div>
	<base-header class="header pb-8 pt-7 pt-lg-7 d-flex align-items-center">
		<!-- Mask -->
		<!-- <span class="mask bg-gradient-success opacity-8"></span> -->
		<!-- Header container -->
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2 text-white">App Management</h1>
					<p class="text-white mt-0 mb-0">Manage all app data here</p>
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--7">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header bg-transparent">
						<div class="d-flex justify-content-between align-items-center">
							<div class="flex-shrink-1">
								<select v-model="activeTab" class="form-control">
									<option class="" v-for="(tab, index) in tabs" :key="index">
										{{ tab }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="card-body">
						<price-prediction v-if="activeTab == 'Price Prediction'" />
						<process-tracker v-if="activeTab == 'Process Tracker'" />
						<news v-if="activeTab == 'News'" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import PricePrediction from "./AppManagementSubViews/PricePrediction";
import ProcessTracker from "./AppManagementSubViews/ProcessTracker";
import News from "./AppManagementSubViews/News";

export default {
	name: "app-management",
	components: {
		PricePrediction,
		ProcessTracker,
		News,
	},
	data() {
		return {
			activeTab: "Price Prediction",
			tabs: ["Price Prediction", "Process Tracker", "News"],
		};
	},
	methods: {
		selectTab(tab) {
			this.activeTab = tab;
		},
	},
};
</script>

<style scoped>
.cursor-pointer {
	cursor: pointer;
}
</style>

<template>
	<div>
		<div class="card mt--7">
			<div class="container-fluid align-items-center">
				<div class="row mt-5">
					<div class="col">
						<h1 class="display-3">Farmer Dashboard</h1>
					</div>
				</div>

				<!-- Header card -->
				<div class="row mt-5">
					<div
						v-for="(headerCard, index) in headerCardData"
						class="col-sm-12 col-md-12 col-lg-4"
						:key="index"
					>
						<header-card
							class="m-4"
							:headerCardData="headerCard"
						></header-card>
					</div>
				</div>

				<!--Selectors -->
				<!-- <div class="row">
					<div
						v-for="(selector, index) in selectors"
						:key="index"
						class="col-lg-4 mt-3 px-5"
					>
						<label class="form-control-label">{{
							selector.heading
						}}</label>
						<select class="rounded m-0 form-control">
							<option
								v-for="(type, index) in selector.dataStore"
								:key="index"
							>
								{{ type }}
							</option>
						</select>
					</div>
				</div> -->

				<!--Search and Add  -->
				<div class="row mt-5 px-lg-5">
					<div
						class="col-lg-3 d-flex align-items-center justify-content-between"
					>
						<base-input
							placeholder="Search"
							class="input-group-alternative mt-3"
							v-model="searchQuery"
							addon-right-icon="fas fa-search"
						>
						</base-input>
					</div>
					<!-- <div
						class="col-lg-3 d-flex align-items-center justify-content-between"
					>
						<select
							class="d-inline-block form-control"
							placeholder="Region"
							v-model="state"
						>
							<option :value="null">Select Districts</option>
							<option
								v-for="state in $store.state.regionsData
									.stateList"
								:key="state.id"
								:value="state.id"
							>
								{{ state.name }}
							</option>
						</select>
					</div> -->
					<div
						class="col-lg-3 d-flex align-items-center justify-content-between"
					>
						<select
							class="d-inline-block form-control"
							placeholder="Region"
							v-model="region"
							@change="fetchSubregion()"
						>
							<option :value="null">Select region</option>

							<option
								v-for="region in $store.state.regionsData
									.regions"
								:key="region.id"
								:value="region.id"
							>
								{{ region.name }}
							</option>
						</select>
					</div>
					<div
						class="col-lg-3 d-flex align-items-center justify-content-between"
					>
						<select
							class="d-inline-block form-control"
							placeholder="Sub Region"
							v-model="sub_region"
						>
							<option :value="null">Select Sub region</option>
							<option
								v-for="sub_region in $store.state.regionsData
									.sub_regions"
								:key="sub_region.id"
								:value="sub_region.id"
							>
								{{ sub_region.name }}
							</option>
						</select>
					</div>
					<!-- <div class="col mr-4 text-right mt-4">
						<base-button
							type="button"
							class="btn btn-primary"
							@click.prevent="addFarmer"
						>
							Add Farmer
						</base-button>
					</div> -->
				</div>
			</div>

			<!-- Table -->
			<div class="my-3 px-lg-7">
				<div class="card shadow">
					<div class="card-header border-0 text-center">
						<h2 class="mb-0">Farmers List</h2>
					</div>
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<base-table
									class="table align-items-center table-flush"
									title=""
									thead-classes="thead-light"
									tbody-classes="list"
									type="hover"
									:data="filteredFarmerList"
								>
									<template slot="columns">
										<th>Sl No</th>
										<th>Name</th>
										<th>Actions</th>
									</template>

									<template slot-scope="{ row, index }">
										<td>
											{{
												pageSize * (currentPage - 1) +
												(index + 1)
											}}
										</td>
										<td>{{ row.name }}</td>
										<td>
											<i
												class="btn fas fa-eye"
												@click.prevent="
													goToFarmerList(row.id)
												"
											></i>
											<i
												class="btn fas fa-trash text-danger"
												@click.prevent="
													deleteItemIndex =
														farmerList.indexOf(
															filteredFarmerList[
																index
															]
														)
												"
											></i>
										</td>
									</template>
								</base-table>
							</div>
						</div>
					</div>
					<base-pagination
						class="mt-3 mr-3"
						:perPage="pageSize"
						:total="farmerCount"
						v-model="currentPage"
						align="center"
					></base-pagination>
					<delete-dialog
						v-model="deleteItemIndex"
						:data="farmerList"
						item="farmer"
						@performingAction="displayLoader = true"
						@actionComplete="displayLoader = false"
						@actionFailed="displayLoader = false"
						actionString="deleteFarmer"
					>
						<span slot="warning"
							>All data associated with this farmer including
							their land, crops and more will get deleted
							too</span
						>
					</delete-dialog>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";

export default {
	name: "farmer-dashboard",
	components: {
		DeleteDialog,
	},
	props: {},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			searchQuery: "",
			pageSize: 15,
			currentPage: 1,
			displayLoader: false,
			region: null,
			sub_region: null,
			state: null,
			selectors: [
				{
					heading: "Crop",
					dataStore: this.$store.state.cropData.cropList,
				},
				{
					heading: "Variety",
					dataStore: this.$store.state.farmerData.varieties,
				},
				{
					heading: "Locality",
					dataStore: this.$store.state.farmerData.locality,
				},
				{
					heading: "Subscription Plan",
					dataStore: this.$store.state.farmerData.subscription,
				},
				{
					heading: "Grade",
					dataStore: this.$store.state.farmerData.grade,
				},
				{
					heading: "Executive",
					dataStore: this.$store.state.farmerData.executive,
				},
			],
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.farmerList.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		goToFarmerList(farmerID) {
			this.$router.push("/farmers-section/" + farmerID + "/dashboard");
		},
		addFarmer() {
			this.$router.push("/farmers-section/add-farmer");
		},
		fetchFarmerList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchFarmerList", {
					pageSize: this.pageSize,
					page: this.currentPage,
					region: this.region,
					sub_region: this.sub_region,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch farmer list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchExecutiveFarmerList() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchExecutiveFarmerList")
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch farmer list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		handleChange() {
			this.fetchFarmerList();
		},
		fetchState() {
			this.$store.dispatch("fetchState").catch((error) => {
				this.$notify({
					title: "Failed to fetch states",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchRegion() {
			this.$store.dispatch("fetchRegion", this.state).catch((error) => {
				this.$notify({
					title: "Failed to fetch regions",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchSubregion() {
			if (
				this.region !== null ||
				this.region !== "" ||
				this.region !== undefined
			)
				this.$store
					.dispatch("fetchSubRegion", this.region)
					.catch((error) => {
						this.$notify({
							title: "Failed to fetch sub regions",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
		},
	},
	computed: {
		headerCardData() {
			return this.$store.state.farmerData.headerCardData;
		},
		farmerList() {
			return this.$store.state.farmerData.farmerList;
		},
		farmerCount() {
			return this.$store.state.farmerData.farmerCount;
		},
		filteredFarmerList() {
			var filteredFarmerList = this.farmerList;
			if (this.searchQuery !== "") {
				filteredFarmerList = this.farmerList.filter((item) =>
					item.name
						.toLowerCase()
						.startsWith(this.searchQuery.toLowerCase())
				);
			}
			return filteredFarmerList;
		},
		// pagedFarmerList() {
		// 	var start = (this.currentPage - 1) * this.pageSize;
		// 	return this.filteredFarmerList.slice(start, start + this.pageSize);
		// },
		token() {
			return localStorage.getItem("decoded-token");
		},
	},
	watch: {
		currentPage() {
			this.fetchFarmerList();
		},
		state() {
			if (this.state === null && this.state === "" && this.state === undefined
			) {
				this.fetchRegion();
			}
			this.fetchFarmerList();
		},
		region() {
			if (this.region === null && this.region === "" && this.region === undefined) {
				this.fetchSubregion();
			}

			this.fetchFarmerList();
		},
		sub_region() {
			this.fetchFarmerList();
		},
	},
	mounted() {
		this.fetchState();
		if (this.token === "admin") {
			this.fetchFarmerList();
		} else {
			this.fetchExecutiveFarmerList();
		}
	},
};
</script>
<style scoped></style>

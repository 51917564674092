<template>
	<div>
		<div class="d-flex justify-content-between my-4 mx-3">
			<h6 class="heading-small text-muted">Personal information</h6>
		</div>
		<div class="pl-lg-4">
			<div class="row mb-3">
				<div class="col-lg-6 form-group">
					<label class="form-control-label"
						>Profile picture (Optional)</label
					>
					<input
						class="form-control"
						type="file"
						@change="onFileChange"
					/>
				</div>
				<div class="col-lg-6">
					<base-input
						label="Name"
						input-classes="form-control"
						v-model="data.name"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<base-input
						label="Mobile Number"
						input-classes="form-control"
						v-model="data.mobileNo"
						type="text"
					/>
				</div>
				<!-- <div class="col-lg-6">
					<base-input
						label="Whatsapp Number"
						input-classes="form-control"
						v-model="data.whatsapp_number"
						type="text"
					/>
				</div> -->
				<div class="col-lg-6">
					<base-input
						label="Date of birth"
						input-classes="form-control"
						v-model="data.date_of_birth"
						type="date"
					/>
				</div>
			</div>
			<div class="row">
				<!-- <div class="col-lg-6">
					<base-input
						label="Email ID"
						input-classes="form-control"
						v-model="data.email"
						type="email"
					/>
				</div>
				<div class="col-lg-6">
					<base-input
						label="Address"
						input-classes="form-control"
						v-model="data.address"
					/>
				</div> -->
				
				<!-- <div class="col-lg-6" v-if="token==='admin'">
					<label class="form-control-label">Executive assigned</label>
					<select
						class="d-inline-block form-control"
						placeholder="Executive assigned"
						v-model="data.executive"
					>
						<option :value="undefined">No executive</option>

						<option
							v-for="executive in executives"
							:key="executive.id"
							:value="executive.id"
						>
							{{ executive.name }}
						</option>
					</select>
				</div> -->
				<div class="col-lg-6" v-if="farmerID === undefined">
					<base-input
						label="Password"
						input-classes="form-control"
						v-model="data.password"
						type="password"
					/>
				</div>
				<div class="col-lg-6" v-if="farmerID === undefined">
					<base-input
						label="Retype password"
						input-classes="form-control"
						v-model="data.repassword"
						type="password"
					/>
				</div>
				<div class="col-lg-4">
					<label class="form-control-label"
						>Choose Income Range</label
					>
					<select
						class="d-inline-block form-control"
						placeholder="Choose Income Range"
						v-model="data.income"
					><option value="">All</option>
						<option value="3">Above 1 Lakh Annually</option>
						<option value="2">
							Between 1 Lakh and 50000 Annually
						</option>
						<option value="1">Below 50000 Annually</option>
					</select>
				</div>
				<!-- <div :class="token==='admin'?'col-lg-3':'col-lg-6'">
					<label class="form-control-label">States</label>
					<select
						class="d-inline-block form-control"
						placeholder="State"
						v-model="data.state"
						@change="fetchRegion()"
					>
						<option :value="null">No State</option>

						<option
							v-for="state in $store.state.regionsData.stateList"
							:key="state.id"
							:value="state.id"
						>
							{{ state.name }}
						</option>
					</select>
				</div> -->
				<div :class="token==='admin'?'col-lg-4':'col-lg-6'">
					<label class="form-control-label">Region</label>
					<select
						class="d-inline-block form-control"
						placeholder="Region"
						v-model="data.region"
						@change="fetchSubregion()"
					>
						<option :value="null">No region</option>

						<option
							v-for="region in $store.state.regionsData.regions"
							:key="region.id"
							:value="region.id"
						>
							{{ region.name }}
						</option>
					</select>
				</div>
				<div :class="token==='admin'?'col-lg-4':'col-lg-6'">
					<label class="form-control-label">Sub Region</label>
					<select
						class="d-inline-block form-control"
						placeholder="Sub Region"
						v-model="data.sub_region"
					>
						<option :value="null">No Sub region</option>
						<option
							v-for="sub_region in $store.state.regionsData
								.sub_regions"
							:key="sub_region.id"
							:value="sub_region.id"
						>
							{{ sub_region.name }}
						</option>
					</select>
				</div>
			</div>
		</div>

		<hr class="my-4 py-2" />

		<div class="d-flex justify-content-between my-4 mx-3">
			<h6 class="heading-small text-muted">Land information</h6>
			<div
				class="btn border border-success py-2 px-3 ml-3 text-right"
				@click.prevent="createNewLand()"
			>
				+
			</div>
		</div>
		<div class="pl-lg-4 mb-5">
			<div class="row mb-4">
				<div class="col-lg-1 form-control-label mt-2 px-2 pt-1">
					Sl no.
				</div>
				<div class="col-lg-2 form-control-label mt-2 px-2 pt-1">
					Land name
				</div>
				<div class="col-lg-1 form-control-label mt-2 px-2 pt-1">
					Latitude
				</div>
				<div class="col-lg-1 form-control-label mt-2 px-2 pt-1">
					Longitude
				</div>
				<div class="col-lg-1 form-control-label mt-2 px-2 pt-1">
					Area size
				</div>
				<div class="col-lg-2 form-control-label mt-2 px-2 pt-1">
					Area unit
				</div>
				<div class="col-lg-2 form-control-label mt-2 px-2 pt-1">
					Crop
				</div>
				<div class="col-lg-1 form-control-label mt-2 px-2 pt-1">
					Action
				</div>
			</div>
			<div
				class="row mb-4"
				v-for="(landItem, index) in lands"
				:key="index"
			>
				<div class="col-lg-1 px-2">
					<div class="form-control-label mt-2 pt-1">
						{{ index + 1 }}.
					</div>
				</div>
				<div class="col-lg-2 px-2">
					<input
						class="d-inline-block form-control"
						placeholder="Land name"
						v-model="landItem.name"
					/>
				</div>
				<div class="col-lg-1 px-2">
					<input
						class="d-inline-block form-control"
						placeholder="Latitude"
						v-model="landItem.latitude"
					/>
				</div>
				<div class="col-lg-1 px-2">
					<input
						class="d-inline-block form-control"
						placeholder="Longitude"
						v-model="landItem.longitude"
					/>
				</div>
				<div class="col-lg-1 px-2">
					<input
						class="d-inline-block form-control"
						placeholder="Area size"
						v-model="landItem.area"
						type="number"
					/>
				</div>
				<div class="col-lg-2 px-2">
					<select
						class="d-inline-block form-control"
						placeholder="Area unit"
						v-model="landItem.area_unit"
					>
						<option
							v-for="(unit, index) in areaUnits"
							:key="index"
							:value="unit"
						>
							{{ unit }}
						</option>
					</select>
				</div>
				<div class="col-lg-2 px-2">
					<multiselect
						class="custom-multi-select"
						placeholder="Choose crops"
						open-direction="bottom"
						:searchable="true"
						:multiple="true"
						v-model="landItem.crop"
						:options="cropList"
						label="name"
						track-by="name"
						:hide-selected="true"
						:close-on-select="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">
							{{ option.name }}
						</template>
					</multiselect>
				</div>
				<div class="col-lg-2 px-2">
					<i
						v-if="farmerID != undefined"
						class="btn fas fa-check text-success"
						@click.prevent="landEdit(index)"
					/>
					<i
						class="btn fas fa-trash text-danger"
						@click.prevent="
							landItem.id != undefined
								? (deleteItemIndex = index)
								: lands.splice(index, 1)
						"
					/>
				</div>
				<!-- <div  class="col-lg-1 px-2">
					
				</div> -->
			</div>
		</div>

		<div class="button-bar text-right mt-4">
			<button class="btn btn-danger" @click.prevent="$router.back()">
				Cancel
			</button>
			<button class="btn btn-success" @click.prevent="addEditFarmer">
				Done
			</button>
		</div>
		<delete-dialog
			:data="lands"
			v-model="deleteItemIndex"
			actionString="deleteLand"
			item="land"
			:miscData="{ farmerID: farmerID }"
			@actionComplete="
			fetchLandDetails()
				displayLoader = false;
				deleteItemIndex = -1;
			"
			@actionCancelled="deleteItemIndex = -1"
			@performingAction="displayLoader = true"
			@actionFailed="displayLoader = false"
		>
			<span slot="warning">This data will be removed!</span>
		</delete-dialog>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
import DeleteDialog from "../ItemDeleteDialog";

export default {
	components: { Multiselect, DeleteDialog },
	name: "add-farmer-profile",
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			displayLoader: false,
			files: [],
			profile_photo: null,
			farmer_id: null,
			data: {
				name: "",
				email: "",
				mobileNo: "",
				whatsapp_number:"",
				address: "",
				executive: undefined,
				password: undefined,
				repassword: undefined,
				date_of_birth: "",
				sub_region: null,
				region: null,
				state:null,
				income: null,
				land: [],
			},
			lands: [],
			deleteItemIndex: -1,
		};
	},
	methods: {
		createNewLand() {
			this.lands.push({
				name: "",
				latitude: 0,
				longitude: 0,
				area: 0,
				area_unit: this.areaUnits[0],
				crop: [],
			});
		},
		landEdit(index) {
			var data = this.lands[index];
			this.$store
				.dispatch("updateFarmerLandDetails", {
					farmerID: this.farmerID,
					landData: data,
				})
				.then(() => {
					this.displayLoader = false;
					this.$router.back();
				})
				.catch((error) => {
					this.displayLoader = false;
					this.$notify({
						title: "Failed to update farmer profile",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.profile_photo = this.files[0].name;
		},
		addEditFarmer() {
			this.data.income=parseInt(this.data.income);
			if (this.data.password !== this.data.repassword) {
				this.$notify({
					title: "Passwords do not match",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			if (this.data.sub_region === null) {
				this.$notify({
					title: "Region and Sub Region Must be selected",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			
			var formData = new FormData();
			formData.append("profile_photo", this.files[0]);
			if (this.farmerID === undefined) {
				this.data["land"] = this.lands;
				if (this.data["land"][0].name ==='') {
				this.$notify({
					title: "Land and crops must be added",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
				this.displayLoader = true;
				this.$store
					.dispatch("updateFarmerProfile", this.data)
					.then(() => {
						this.displayLoader = false;
						this.farmer_id = this.$store.state.farmerData.farmer_id;
						if (this.farmer_id != null) {
							if (this.files.length != 0) {
								this.$store.dispatch("uploadProfilePic", {
									data: formData,
									farmerID: this.farmer_id,
								});
							}
						}
						this.$router.back();
					})
					.catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to update farmer profile",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
			} else {
				this.data["land"] = this.lands;
				delete this.data.password;
				delete this.data.repassword;
				this.displayLoader = true;
				this.$store
					.dispatch("updateFarmerProfile", this.data)
					.then(() => {
						this.displayLoader = false;
						if (this.files.length != 0) {
							this.$store.dispatch("uploadProfilePic", {
								data: formData,
								farmerID: this.farmerID,
							});
						}
						this.$router.back();
					})
					.catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to update farmer profile",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
			}
		},
		fetchState() {
			this.$store.dispatch("fetchState")
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch states",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchRegion() {
			this.$store.dispatch("fetchRegion",this.data.state)
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch regions",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchSubregion(){
			if (this.data.region !== null)
				this.$store
					.dispatch("fetchSubRegion", this.data.region)
					.catch((error) => {
						this.$notify({
							title: "Failed to fetch sub regions",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
		},
		fetchLandDetails() {
			this.$store
					.dispatch("fetchFarmerLandDetails", this.farmerID)
					.then(() => {
						this.lands = JSON.parse(
							JSON.stringify(
								this.$store.state.farmerData.farmerLandDetails
							)
						);
					});
		}
	},
	computed: {
		areaUnits() {
			return this.$store.state.farmerData.areaUnits;
		},
		cropList() {
			return this.$store.state.cropData.cropLists;
		},

		executives() {
			return this.$store.state.userData.userList.filter(
				(user) => user.userType === "executive"
			);
		},
		token() {
			return localStorage.getItem('decoded-token');
		},
	},
	watch: {
		selectedLandID() {
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},

	},
	mounted() {
			this.fetchState()
			if (this.$store.state.userData.userList.length == 0) {
				this.$store.dispatch("fetchUserList").catch((error) => {
					this.data.executive = undefined;
					this.$notify({
						title: "Failed to fetch executive list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
			}
			this.$store.dispatch("fetchCropList").catch((error) => {
				this.$notify({
					title: "Failed to fetch crop list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});

			if (this.farmerID) {
				this.displayLoader = true;
				this.$store
					.dispatch("fetchFarmerProfile", this.farmerID)
					.then(() => {
						this.displayLoader = false;
						
						this.data = JSON.parse(
							JSON.stringify(
								this.$store.state.farmerData.farmerProfile
							)
						);
						if(this.data.region !=''){

							this.fetchSubregion()
						}
					})
					.catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to fetch farmer profile",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
				this.$store
					.dispatch("fetchFarmerLandDetails", this.farmerID)
					.then(() => {
						this.lands = JSON.parse(
							JSON.stringify(
								this.$store.state.farmerData.farmerLandDetails
							)
						);
					});
				if (this.$store.state.farmerData.farmerProfile.length > 0) {
					this.lands = [];
				}
			} else {
				this.createNewLand();
			}
		
	},
	
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

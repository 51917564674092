<template>
<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
	<side-bar :background-color="sidebarBackground">
		<template slot="links">

			<sidebar-item :link="{name: 'Dashboard', icon: 'fa fa-home text-primary', path: '/dashboard'}" />
			<!-- <sidebar-item v-if="token==='admin'" :link="{name: 'Crops', icon: 'fa fa-leaf text-green', path: '/crops'}" /> -->
			<sidebar-item v-if="token==='admin'" :link="{name: 'Users', icon: 'fa fa-user text-orange', path: '/users'}" />
			<sidebar-item :link="{name: 'Farmer Dashboard', icon: 'fa fa-leaf text-green', path: '/farmers-section'}" />
			<!-- <sidebar-item v-if="token==='admin'" :link="{name: 'Executive Dashboard', icon: 'fa fa-users text-danger', path: '/executive-section'}" /> -->
			<!-- <sidebar-item v-if="token==='admin'" :link="{name: 'Reports', icon: 'fa fa-file text-black', path: '/report-analysis'}" /> -->
			<sidebar-item v-if="token==='admin'" :link="{name: 'Data', icon: 'fa fa-database text-danger', path: '/datamanagement'}" />
			<sidebar-item v-if="token==='admin'" :link="{name: 'Schemes', icon: 'fas fa-shopping-basket text-green', path: '/schemes'}" />
			<sidebar-item v-if="token==='admin'" :link="{name: 'News', icon: 'fa fa-newspaper text-black', path: '/news'}" />
			<sidebar-item v-if="token==='admin'" :link="{name: 'Apps', icon: 'fa fa-mobile text-black', path: '/appmanagement'}" />
			<!-- <sidebar-item :link="{name: 'Calendar', icon: 'fa fa-calendar text-yellow', path: '/calendar'}" /> -->
			<sidebar-item v-if="token==='admin'" :link="{name: 'Contact', icon: 'ni ni-pin-3 text-orange', path: '/contact'}"/>

		</template>
	</side-bar>
	<div class="main-content" :data="sidebarBackground">
		<dashboard-navbar></dashboard-navbar>

		<div @click="toggleSidebar">
			<fade-transition :duration="200" origin="center top" mode="out-in">
				<!-- your content here -->
				<router-view></router-view>
			</fade-transition>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
	</div>
</div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {
	FadeTransition
} from 'vue2-transitions';

export default {
	components: {
		DashboardNavbar,
		ContentFooter,
		FadeTransition
	},
	data() {
		return {
			sidebarBackground: 'blue' //vue|blue|orange|green|red|primary
		};
	},
	computed:{
		token(){
			return localStorage.getItem('decoded-token');
		}
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		}
	},
		
};
</script>
<style lang="scss">
</style>

<template>
<base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="false" expand>
	<form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
		<div class="form-group mb-0">
			<base-input placeholder="Search" class="input-group-alternative" v-model="searchQuery"
				@focus="showSearchMenu = true" addon-right-icon="fas fa-search" v-click-outside="closeSearch">
				<template v-if="showSearchMenu" v-slot:infoBlock>
					<nav class="position-absolute p-2 bg-white w-100 rounded suggestions-box">
						<div class="text-left position-absolute pt-1 pl-1">
							Search results
						</div>
						<div class="text-right">
							<div class="btn mb-1 p-1 pl-2 pr-2" @click.stop="closeSearch">x</div>
						</div>
						<div class="search-scroller">
							<div v-for="(suggestion, index) in filteredSuggestions" :key="index" @click.stop="goToPage(suggestion.toPage)"
								class="p-2 mb-2 rounded suggestion-box">
								<strong>{{ suggestion.title }}</strong>
								<br>
								{{ suggestion.subTitle }}
							</div>
						</div>
					</nav>
				</template>
			</base-input>
		</div>
	</form>
	<ul class="navbar-nav align-items-center d-none d-md-flex">
		<li class="nav-item dropdown">
			<notification-panel></notification-panel>
		</li>
		<li class="nav-item dropdown">
			<base-dropdown class="nav-link pr-0 pl-0" position="right">
				<a slot="title" class="media align-items-center nav-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span class="avatar avatar-sm rounded-circle">
						<img alt="User icon" :src="$store.state.accountData.profilePicture">
					</span>
					<div class="media-body ml-2 d-none d-lg-block">
						<span class="mb-0 text-sm font-weight-bold">{{ $store.state.accountData.username }}</span>
					</div>
				</a>

				<mini-nav></mini-nav>
			</base-dropdown>
		</li>
	</ul>
</base-nav>
</template>
<script>
import MiniNav from './MiniNav.vue';
import NotificationPanel from './NotificationPanel.vue';

export default {
	components: {
		MiniNav,
		NotificationPanel
	},
	data() {
		return {
			searchSuggestions: [
				{
					title: 'Dashboard',
					subTitle: 'See the overall product performance at a glance',
					keywords: 'dashboard home',
					toPage: '/dashboard'
				},
				// {
				// 	title: 'Crops',
				// 	subTitle: 'Manage crop data here',
				// 	keywords: 'crop crops pepper coffee cardamom',
				// 	toPage: '/crops'
				// },
				{
					title: 'Report and Analysis',
					subTitle: 'Read reports and their analysis',
					keywords: 'report analysis',
					toPage: '/report-analysis'
				},
				{
					title: 'Users',
					subTitle: 'Manage user data',
					keywords: 'users',
					toPage: '/users'
				},
				{
					title: 'Farmer Dashboard',
					subTitle: 'See all farmer related data at a glance',
					keywords: 'farmers dashboard',
					toPage: '/farmers-section'
				},
				{
					title: 'Calendar',
					subTitle: 'Manage your activities',
					keywords: 'calendar activity activities schedule meeting',
					toPage: '/calendar'
				},
				{
					title: 'App Management',
					subTitle: 'Manage App data',
					keywords: 'app management',
					toPage: '/appmanagement'
				},
				{
					title: 'Data Management',
					subTitle: 'Manage data files',
					keywords: 'data management files',
					toPage: '/datamanagement'
				},
				{
					title: 'News',
					subTitle: 'Be up to date with what\'s new',
					keywords: 'news',
					toPage: '/news'
				},
				// {
				// 	title: 'Profile',
				// 	subTitle: 'Have a look at your profile information',
				// 	keywords: 'profile account',
				// 	toPage: '/profile'
				// },
				{
					title: 'Contact',
					subTitle: 'Contact us',
					keywords: 'contact help support',
					toPage: '/contact'
				},
			],
			showSearchMenu: false,
			searchQuery: ''
		};
	},
	computed: {
		filteredSuggestions() {
			var ctx = this;
			var searchQuery = this.searchQuery.trim().toLowerCase();
            return this.searchSuggestions.filter((suggestion) => {
                return suggestion.keywords.indexOf(searchQuery) >= 0 && suggestion.toPage != ctx.$route.path;
            });
		}
    },
	methods: {
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		goToPage(page) {
			this.searchQuery = '';
			this.showSearchMenu = false;
			this.$router.push(page);
		},
		closeSearch() {
			this.showSearchMenu = false;
		}
	}
};
</script>
<style scoped>
.suggestions-box {
	box-shadow: 0px 5px 21px 6px #a5a5a5;
	z-index: 1000;
	top: 120%;
	font-size: 14px;
}

.suggestion-box {
	background-color: #f7f7f7;
	transition: .2s background-color;
}

.suggestion-box:hover {
	background-color: #e4e4e4;
}

.search-scroller {
	overflow: auto;
	max-height: 300px;
}
</style>

<template>
<div class="jumbo-page">
	<main class="admin-main">
		<div class="loader-wrapper" :class="{'d-flex': displayLoader}" id="loader-wrapper">
			<img src="../assets/loader.svg" alt="loader">
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-5  bg-white">
					<div class="row align-items-center min-vh-100">
						<div class="mx-auto col-sm-8">
							<div class="p-2 text-center">
								<p>
									<img src="../../public/img/brand/kabco_logo.png" width="120" alt="Kabco Logo">
								</p>
								<p class="admin-brand-content">
									KABCO Farmer App
								</p>
							</div>
							<h3 class="text-center p-2 pb-4 fw-400 text-uppercase">Admin Login</h3>
							<div class="alert alert-danger" :class="{'d-none': !showErrorMessage}" role="alert">
								{{ errorMessage }}
							</div>
							<div class="alert alert-warning" :class="{'d-none': !showCheckEmailMessage}" role="alert">
								Check your email for instructions to reset your password
							</div>
							<form class="needs-validation" id="loginform" method="POST" @submit.prevent="frmSubmit">
								<div class="form-row">
									<div class="form-group floating-label col-md-12">
										<!--<label>Username</label>-->
										<input type="text" required class="form-control" placeholder="Username" name="username" v-model="username">
									</div>
									<div class="form-group floating-label col-md-12">
										<!--<label>Password</label>-->
										<input type="password" required class="form-control" placeholder="Password" name="password" v-model="password">
									</div>
								</div>

								<button type="submit" class="btn btn-primary btn-block btn-lg" id="login-button">Login</button>

							</form>
							<div class="text-right pt-1">
								<span @click.prevent="toggleResetPasswordDialog" class="text-underline small-font">Forgot Password?</span>

								<modal :show="showResetPasswordDialog" type="notice" @close="toggleResetPasswordDialog">
									<template v-slot:header>
										Reset password
									</template>
									<fieldset>
										<div class="pb-3 pt-2 pl-5 pr-5 text-center">Type in your email ID to reset your password</div>
										<input class="form-control input-lg" placeholder="Email ID" v-model="resetemail" id="email-textbox" name="email" type="email">
									</fieldset>
									<template v-slot:footer>
										<button class="btn btn-success" @click.prevent="resetPassword">Reset password</button>
									</template>
								</modal>
							</div>
						</div>

					</div>
				</div>
				<div class="col-lg-7 bg-cover image-box">
					<img src="../../public/img/brand/kabco_logo.png" width="800" alt="Kabco Logo">

				</div>
			</div>
		</div>
	</main>
</div>
</template>
<script>
export default {
	name: 'login',
	data() {
		return {
			showErrorMessage: false,
			errorMessage: '',

			showResetPasswordDialog: false,
			showCheckEmailMessage: false,
			displayLoader: false,

			username: '',
			password: '',
			resetemail: ''
		}
	},
	methods: {
		toggleResetPasswordDialog() {
			this.resetemail = '';
			this.showResetPasswordDialog = !this.showResetPasswordDialog;
		},
		showError(error) {
			this.errorMessage = error;
			this.showErrorMessage = true;
		},
		resetPassword() {
			if (this.resetemail.trim() == '') {
				this.toggleResetPasswordDialog();
				return;
			}

			this.showErrorMessage = false;
			this.showCheckEmailMessage = true;
			this.toggleResetPasswordDialog();
			this.$notify({
				title: "Backend API unavailable",
				icon: 'fa fa-exclamation-circle',
				type: 'warning',
				message: 'Feature will be implemented once the backend API is ready',
			});
		},
		async frmSubmit() {
			this.displayLoader = true;
			this.showCheckEmailMessage = false;
			this.showErrorMessage = false;

			const {
				username,
				password
			} = this
			
			this.$store.dispatch('login', {
				username,
				password
			}).then(() => {
				this.$router.push(this.$route.query.redirect || '/dashboard');
				
				
			}).catch((error) => {
				this.displayLoader = false;
				this.showError(error);
			});

			return false;
		}
	}
}
</script>
<style scoped>
.text-underline {
	text-decoration: underline;
	cursor: pointer;
}

.small-font {
	font-size: 14px;
}

.image-box {
	background-image: url('https://www.deepflow.in/assets/images/bg.svg');
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;;
}
</style>

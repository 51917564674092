<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center">
					<div class="col-10">
						<h1 class="display-4 m-0">Recommendations</h1>
					</div>
					<div class="col-2 text-right">
						<base-button
							type="button"
							class="btn-sm btn-danger"
							@click.prevent="report"
						>
							Report
						</base-button>
					</div>
				</div>
			</div>
			<!--Search and Date -->
			<div class="card-body">
				<div class="container">
					<div class="row">
						<base-input
							placeholder="Search"
							class="input-group-alternative col-lg-3"
							v-model="searchQuery"
							addon-right-icon="fas fa-search"
						>
						</base-input>
						<select
							class="form-control col-lg-2 ml-3"
							placeholder="Status"
							v-model="status"
						>
							<option value="">All</option>
							<option value="pending">Pending</option>
							<option value="verified">Verified</option>
							<option value="changed">Changed</option>
							<option value="created">Created</option>
						</select>
						<label class="form-control-label mt-2 col text-center"
							>Start date</label
						>
						<base-input
							type="date"
							class="col-lg-2"
							v-model="startDate"
						/>
						<label class="form-control-label mt-2 col text-center"
							>End date</label
						>
						<base-input
							type="date"
							class="col-lg-2"
							v-model="endDate"
						/>
					</div>
				</div>

				<!-- Table -->
				<div class="mb-3">
					<div class="card shadow">
						<div class="col card-header text-center">
							<div class="row">
								<div class="col-10">
									<h2>Recommendations</h2>
								</div>
								<div class="col-2 text-right">
									<base-button
										v-if="token === 'admin'"
										type="button"
										class="btn-sm btn-primary"
										@click.prevent="
											addRecommendation(farmerID)
										"
									>
										Add New +
									</base-button>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="table-responsive">
									<base-table
										class="table align-items-center table-flush"
										title=""
										thead-classes="thead-light"
										tbody-classes="list"
										type="hover"
										:data="pagedTableData"
									>
										<template slot="columns">
											<th>Sl No</th>
											<th
												v-for="(
													header, index
												) in tableHeaders"
												v-on:click="sortTable(header)"
												:key="index"
											>
												{{ header }}
												<div
													class="float-right"
													v-if="
														header.toLowerCase() ==
														sortColumn
													"
												>
													<i
														v-bind:class="[
															ascending
																? 'fas fa-arrow-up'
																: 'fas fa-arrow-down',
														]"
													></i>
												</div>
											</th>
											<th v-if="token === 'admin'">
												Actions
											</th>
										</template>

										<template slot-scope="{ row, index }">
											<td>{{ index + 1 }}</td>
											<td>{{ row.date }}</td>
											<td>{{ row.recommendation }}</td>
											<td>{{ row.section }}</td>
											<td>{{ row.status }}</td>
											<td v-if="token === 'admin'">
												<i
													class="btn fas fa-eye"
													@click.prevent="
														editData(row, true)
													"
												></i>
												<i
													class="btn fas fa-pen"
													@click.prevent="
														editData(row, false)
													"
												></i>
												<i
													class="btn fas fa-trash text-danger"
													@click.prevent="
														deleteItemIndex =
															tableData.indexOf(
																pagedTableData[
																	index
																]
															)
													"
												></i>
												<i
													class="btn fas fa-check text-success"
													@click.prevent="
														verifyRecommendation(
															row
														)
													"
												></i>
											</td>
										</template>
									</base-table>
								</div>
							</div>
						</div>
						<base-pagination
							class="mt-3 mr-3"
							:perPage="pageSize"
							:total="tableData.length"
							v-model="currentPage"
							align="center"
						></base-pagination>
						<delete-dialog
							v-model="deleteItemIndex"
							:data="tableData"
							item="recommendation"
							@performingAction="displayLoader = true"
							@actionComplete="displayLoader = false"
							@actionFailed="displayLoader = false"
							actionString="deleteRecommendation"
						/>
					</div>
				</div>
			</div>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import DeleteDialog from "../ItemDeleteDialog";
export default {
	name: "recommendations",
	components: {
		DeleteDialog,
	},
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		},
	},
	data() {
		return {
			ascending: false,
			sortColumn: "",
			deleteItemIndex: -1,
			pageSize: 15,
			displayLoader: false,
			currentPage: 1,
			searchQuery: "",
			startDate: null,
			endDate: null,
			status: "",
		};
	},
	methods: {
		sortTable: function sortTable(header) {
			var col = header.toLowerCase();
			if (this.sortColumn === col) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortColumn = col;
			}
			var ascending = this.ascending;
			this.tableData.sort(function (a, b) {
				if (a[col] > b[col]) {
					return ascending ? 1 : -1;
				} else if (a[col] < b[col]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},
		addRecommendation() {
			this.$router.push({
				name: "add recommendation",
				params: {
					crop: this.selectedCrop,
					landID: this.selectedLandID,
				},
			});
		},
		editData(row, viewOnly) {
			var editItemIndex = this.tableData.indexOf(row);
			var itemData = Object.assign({}, this.tableData[editItemIndex]);
			this.$router.push({
				name: "edit recommendation",
				params: {
					editItem: {
						data: itemData,
						index: editItemIndex,
					},
					crop: this.selectedCrop,
					landID: this.selectedLandID,
					viewOnly,
				},
			});
		},
		report() {
			this.$router.push(
				"/farmers-section/" +
					this.farmerID +
					"/dashboard/recommendations/report"
			);
		},

		verifyRecommendation(row) {
			this.$store
				.dispatch("verifyRecommendationDetails", {
					recommendationID: row.id,
				})
				.then(() => {
					this.$notify({
						title: "Recommendation verified",
						icon: "fa fa-exclamation-triangle",
						type: "success",
						message: "Recommendation verified successfully",
					});
					this.fetchRecommendations();
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to verify recommendation",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		fetchRecommendations() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchRecommendations", {
					status: this.status,
					farmerID: this.farmerID,
					landID: this.selectedLandID,
					searchQuery: this.searchQuery,
					startDate: this.startDate,
					endDate: this.endDate,
					crop: this.selectedCrop,
				})
				.then(() => {
					this.displayLoader = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch recommendations",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
					this.displayLoader = false;
				});
		},
	},
	computed: {
		recommendationData() {
			return this.$store.state.farmerData.recommendationData;
		},
		tableHeaders() {
			return this.recommendationData.tableHeaders;
		},
		tableData() {
			return this.recommendationData.recommendations;
		},
		// filteredTableData() {
		// 	var filteredTableData = this.tableData;
		// 	if (this.searchQuery != "") {
		// 		filteredTableData = this.tableData.filter((item) =>
		// 			item.recommendation.toLowerCase().includes(this.searchQuery)
		// 		);
		// 	} else if (this.startDate != "" && this.endDate != "") {
		// 		filteredTableData = this.tableData.filter(
		// 			(item) =>
		// 				item.date >= this.startDate && item.date <= this.endDate
		// 		);
		// 	}

		// 	return filteredTableData;
		// },
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		},
		token() {
			return localStorage.getItem("decoded-token");
		},
	},
	mounted() {
		this.$store
			.dispatch("fetchFarmerland", this.farmerID)
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch Land",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		if (this.selectedLandID != undefined) {
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		}
		if (this.selectedCrop != undefined) {
			this.fetchRecommendations();
		}
	},
	watch: {
		selectedLandID() {
			this.$store
				.dispatch("fetchFarmerCrops", this.selectedLandID)
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
			if (this.selectedCrop != undefined) {
				this.fetchRecommendations();
			}
		},
		selectedCrop() {
			this.fetchRecommendations();
		},
		searchQuery() {
			this.fetchRecommendations();
		},
		startDate() {
			this.fetchRecommendations();
		},
		endDate() {
			this.fetchRecommendations();
		},
		status() {
			this.fetchRecommendations();
		},
	},
};
</script>
<style lang="scss" scoped></style>

<template>
<footer class="footer px-4">
	<div class="row align-items-center justify-content-lg-between">
		<div class="col-lg-6">
			<div class="copyright text-center text-lg-left text-muted">
				Developed By <a href="https://www.deepflow.in" class="font-weight-bold ml-1" target="_blank">DeepFlow Technologies</a>
			</div>
		</div>
		<div class="col-lg-6">
			<ul class="nav nav-footer justify-content-center justify-content-lg-end">
				<li class="nav-item">
					<a class="nav-link" href="/">Kabco</a>
				</li>
			</ul>
		</div>
	</div>
</footer>
</template>
<script>
export default {
	data() {
		return {
			year: new Date().getFullYear()
		};
	}
};
</script>
<style></style>

<template>
<div>
	<base-header class="header pb-8 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<!-- <span class="mask bg-gradient-success opacity-8"></span> -->
		<!-- Header container -->
		<div class="container-fluid align-items-center">
			<div class="row">
				<div class="col">
					<h1 class="display-2 text-white">Add user</h1>
					<p class="text-white mt-0 mb-0">Add a new user to the system</p>
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--6">
		<div class="row">
			<div class="col">
				<card shadow type="secondary">
					<div slot="header" class="bg-white border-0">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="mb-0">{{ addEditString }} User</h3>
							</div>
						</div>
					</div>

					<h6 class="heading-small text-muted mb-4">User information</h6>
					<div class="pl-lg-4">
						<div class="row">
							<div class="col-lg-12 form-group">
								<label class="form-control-label"> Profile picture (Optional): </label>
								<input @change="onProfilePictureChange" class="form-control" type="file" />
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6">
								<base-input alternative="" label="Name" input-classes="form-control-alternative" v-model="userData.name" />
							</div>
							<div class="col-lg-6">
								<base-input alternative="" label="Email ID" input-classes="form-control-alternative" v-model="userData.email" type="email" />
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6">
								<base-input alternative="" label="Address" input-classes="form-control-alternative" v-model="userData.address" />
							</div>
							<div class="col-lg-6">
								<base-input alternative="" label="Date of Birth" input-classes="form-control-alternative" v-model="userData.date_of_birth" type="date" />
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6">
								<base-input alternative="" label="Mobile Number" input-classes="form-control-alternative" v-model="userData.mobile_number" type="text" />
							</div>
							<div class="col-lg-6 form-group">
								<label class="form-control-label"> User type </label>
								<select v-model="userData.userType" class="form-control">
									<option v-for="(userType, index) in userTypes" :key="index" class="form-control-alternative">{{ userType }}</option>
								</select>
							</div>
						</div>
						<div class="row" v-if="editUserData == undefined">
							<div class="col-lg-6">
								<base-input alternative="" label="Password" input-classes="form-control-alternative" v-model="userData.temppass" type="password" />
							</div>
							<div class="col-lg-6">
								<base-input alternative="" label="Retype Password" input-classes="form-control-alternative" v-model="userData.temppassre" type="password" />
							</div>
						</div>
					</div>

					<div class="button-bar text-right mt-4">
						<button class="btn btn-danger" @click.prevent="$router.push('/users')">Cancel</button>
						<button class="btn btn-success" @click.prevent="addUser">{{ addEditString }}</button>
					</div>
				</card>
			</div>
		</div>
	</div>

	<loader :displayLoader="displayLoader"></loader>
</div>
</template>
<script>
export default {
	name: 'add-user',
	props: {
		editUserData: {
			type: Object,
			required: false
		},
	},
	data() {
		return {
			displayLoader: false,
			
			addEditString: 'Add',
			userData: {
				profilePicture: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
				name: '',
				email: '',
				date_of_birth: '',
				mobile_number: '',
				address: '',
				userype: '',
				temppass: '',
				temppassre: '',
			}
		}
	},
	methods: {
		addUser() {
			// this.userData.email = this.userData.email.trim();
			// this.userData.address = this.userData.address.trim();
			// this.userData.mobileNo = this.userData.mobileNo.trim();

			var error = undefined;

			if (this.userData.name == '') {
				error = 'Name is required';
			} else if (!this.validateEmail(this.userData.email)) {
				error = 'Invalid Email ID';
			} else if (this.userData.address == '') {
				error = 'Address is required';
			} else if (this.userData.date_of_birth == '') {
				error = 'Date of Birth must be set';
			}else if (this.userData.date_of_birth.length>10) {
				error = 'Enter valid date of birth';
			}  else if (this.userData.mobile_number == '') {
				error = 'Mobile number is required';
			}  else if (this.userData.userType == '') {
				error = 'User type must be set';
			} else if (!this.editUserData && this.userData.temppass == '') {
				error = 'Temp password is required';
			} else if (!this.editUserData && this.userData.temppass != this.userData.temppassre) {
				error = 'The passwords entered don\'t match each other';
			} else if (!this.editUserData && this.userData.temppass.length < 8) {
				error = 'Password must be at least 8 characters long';
			}

			if (error) {
				this.$notify({
					title: error,
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
				});
				return;
			}

			var userObj = {
				id:this.userData.id,
				profilePicture: this.userData.profilePicture,
				name: this.userData.name,
				user_type: this.userData.userType,
				address: this.userData.address,
				date_of_birth: this.userData.date_of_birth,
				mobile_number: this.userData.mobile_number,
				email: this.userData.email,
				password:this.userData.temppass,
				isBlocked: false,
			};
			if(this.editUserData){
				userObj.password=this.userData.password;
			}
			// var action;

			// if (this.editUserData) {
			// 	// Edit user
			// 	userObj.isBlocked = this.editUserData.isBlocked;
			// 	userObj.userID = this.editUserData.userID;
			// 	action = 'edit';
			// } else {
			// 	// Add new user
			// 	action = 'add';
			// 	userObj.password = this.userData.temppass;				
			// }

			this.displayLoader = true;

			this.$store.dispatch('addUser', userObj)
			.then(() => {
				this.$router.push('/users');
				this.$store.dispatch('fetchUserList')
			}).catch((error) => {
				this.$notify({
					title: "Failed to add user",
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					message: error.toString(),
				});
			}).then(() => {
				this.displayLoader = false;
			});
		},
		onProfilePictureChange(e) {
			var imageFile = undefined;
			if (e.target) {
				var imageFiles = e.target.files;
				if (!imageFiles) {
					return;
				}
				imageFile = imageFiles[0];
			} else {
				this.userData.profilePicture = e;
				return;
			}

			var ctx = this;

			const reader = new FileReader();
			reader.addEventListener("load", function() {
				// Convert image file to base64 string
				ctx.userData.profilePicture = reader.result;
			}, false);
			reader.readAsDataURL(imageFile);
		},
		validateEmail(email) { // Not a good practice but it works with most common cases
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
	},
	computed: {
		userTypes() {
			return this.$store.state.userData.userTypes;
		},
	},
	mounted() {
		
		if (this.editUserData) {
			// Duplicate the data we're working on
			this.userData = JSON.parse(JSON.stringify(this.editUserData));
			this.addEditString = 'Edit';
		}
	}
};
</script>
<style>
</style>

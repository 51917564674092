<template>
<div>
	<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<!-- <span class="mask bg-gradient-success opacity-8"></span> -->
		<!-- Header container -->
		<div class="container-fluid d-flex align-items-center">
			<div class="row">
				<div class="col-sm-12">
					<h1 class="display-2 text-white">Activities</h1>
					<p class="text-white mt-0 mb-0">Keep track of your work</p>
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt-4">
		<full-calendar></full-calendar>
	</div>
</div>
</template>
<script>
import FullCalendar from './FullCalendar'

export default {
	name: 'calendar',
	components: {
		FullCalendar
	},
	data() {
		return {
		}
	}
};
</script>
<style>
</style>
